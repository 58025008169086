<template>
    <div>
        <v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>
					<v-icon class='mb-1'>mdi-format-list-bulleted</v-icon>
					CATEGORÍAS
				</v-toolbar-title>
				<v-divider
					class="mx-4"
					inset
					vertical
				></v-divider>
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Buscar"
					single-line
					class='mt-5'
				></v-text-field>
				<v-spacer></v-spacer>
				<v-btn 
					small
					color = 'primary'
                    @click="dialog=!dialog"
				> 
					<v-icon>mdi-plus-circle-outline</v-icon> Nueva categoría
				</v-btn>
			</v-toolbar>
			<v-data-table
				:headers="headers"
				:items="dataTable"
				:items-per-page=itemsPerPage
				:search=search
				:loading=loadingTable
				class="elevation-1"
                item-key="product_category_id"
                :key="`list-categories-products-${tenantId}`"
                :options.sync="options"
			>
				<template v-slot:item.actions="{item}">
					<v-btn 
						icon 
                        @click="editCategory(item.product_category_id)"
					>
						<v-icon color='warning' >mdi-pencil</v-icon>
					</v-btn>					
				</template>
			</v-data-table>
		</v-card>
        <v-dialog
			v-model="dialog"
			persistent
			max-width="800px"
		>		
			<v-card>
				<v-card-title>
				<span class="headline">{{dataCategory.product_category_id !='' ? 'Editar' :'Agregar'}} categoría</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_category">
							<v-row>
								<v-col md=4 cols="12">
									<v-text-field
                                        v-model="dataCategory.product_category_id"
                                        label="ID de la categoría"
                                        counter="20"
                                        :rules=required
                                        :disabled=newRegister
                                    ></v-text-field>
								</v-col>
                                <v-col md=4 cols="12">
									<!-- <v-text-field
                                        v-model="dataCategory.product_category_type_id"
                                        label="Tipo de categoría"
                                        counter="20"
                                        :rules=required
                                    ></v-text-field> -->
                                    <v-autocomplete
										v-model="dataCategory.product_category_type_id"
										small
                                        :items=categoryTypes
										item-text="description"
										item-value="product_category_type_id"										
										label="Tipo de categoría"
									></v-autocomplete>
								</v-col>
                                <v-col md=4 cols="12">
									<v-autocomplete
										v-model="dataCategory.primary_parent_category_id"
										small
                                        :items=primaryCategories
										item-text="category_name"
										item-value="product_category_id"										
										label="Categoría padre primaria"
									></v-autocomplete>
								</v-col>
                                <v-col md=4 cols="12">
									<v-text-field
                                        v-model="dataCategory.category_name"
                                        label="Nombre de la categoría"
                                        counter="100"
                                        :rules=required
                                    ></v-text-field>
								</v-col>
                                <v-col cols="8">
									<v-text-field
                                        v-model="dataCategory.description"
                                        label="Descripción de la categoría"
                                        counter="255"
                                    ></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-col class='text-center'>
						<v-btn
							color="info"
							@click="storeCategory()"
                            :loading=loadingBtn
						>
							<v-icon>mdi-content-save</v-icon> Guardar
						</v-btn>
						<v-btn
							
							@click="close"
						>
							<v-icon>mdi-cancel</v-icon> Cancelar
						</v-btn>
						
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'

export default {
    name: 'ProductsCategoryComponent',
    data: () =>({
        headers: [
			{ text: 'Código', value: 'product_category_id' },
			{ text: 'Nombre', value: 'category_name' },
            { text: 'Tipo de categoría', value: 'tipo_cat' },
            { text: 'Descripción', value: 'description' },
            { text: 'Acciones', value: 'actions' },
        ],
        dataTable:[],
        itemsPerPage:15,
        search:null,
        options:{},
        primaryCategories:[],
        categoryTypes:[],
        dialog:false,
        newRegister:false,
        dataCategory:{
            product_category_id:'',
            product_category_type_id:'',
            primary_parent_category_id:'',
            category_name: '',
            description:''
        },
        defaultDataCategory:{
            product_category_id:'',
            product_category_type_id:'',
            primary_parent_category_id:'',
            category_name: '',
            description:''
        },
        required:[v => !!v || 'El campo es requerido'],    
    }),
    computed: {

    	...mapState('master',['loadingTable','tenantId','loadingBtn']),

    },
    watch:{
        options: {
            handler () {
                this.getCategories()
            },
            deep: true
        }
    },
    methods:{

        ...mapMutations('master',['setLoadingTable','setUrl','setLoadingBtn']),

        ...mapActions('master',['requestApi']),

        getCategories(){

            this.setLoadingTable(true)
            this.setUrl('product-category')
            this.dataTable=[]
            this.requestApi({
				method: 'GET',
				data: {}
			}).then(res =>{
                
                this.dataTable = res.data._embedded.product_category
                   
			}).then(()=>{
                this.setLoadingTable(false)
            })

        },

        getPrimaryCategories(){

            this.setLoadingTable(true)
            this.setUrl('product-category/0')
            this.dataTable=[]
            this.requestApi({
				method: 'GET',
				data: {}
			}).then(res =>{
                
                this.primaryCategories = res.data.primaryCategories
                this.categoryTypes= res.data.categoryTypes
                   
			}).then(()=>{

                this.setLoadingTable(false)

            })

        },

        editCategory(productCategoryId){

            let obj = this.dataTable.find(e => e.product_category_id == productCategoryId)
            console.log(obj)
            if(typeof obj !='undefined'){
                Object.assign(this.dataCategory,obj)
                this.newRegister =true
            }else{
                Object.assign(this.dataCategory,this.defaultDataCategory)
            }

            this.dataCategory.product_category_id =  productCategoryId
            this.dialog =true
            
        },

        storeCategory(){

            if(!this.$refs.form_category.validate())
                return

            this.setLoadingBtn(true)
            this.setUrl('product-category')
            
            this.requestApi({
				method: 'POST',
				data: this.dataCategory
			}).then(res =>{
                
                this.close()
                this.getCategories()
                                   
			}).catch(()=>{
                
            }).then(()=>{
                this.setLoadingBtn(false)
            })

        },

        close(){

            this.dataCategory.product_category_id=null
            Object.assign(this.dataCategory,this.defaultDataCategory)
            this.dialog=false
            this.newRegister =false
        },

    },
    created(){

        this.getPrimaryCategories()

    }
}
</script>